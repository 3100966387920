Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("signup-form")) {
        new Vue({
            el: '#signup-form',
            store,
            data: function () {
                return {
                    step: 1,
                    elements: null,
                    titles: null,
                    totalSteps: 0,
                    livingSituationMin: 0,
                    livingSituationMax: 15,
                    livingSituationError: false,
                    livingSituation: [],
                    conditionsMin: 0,
                    conditionsMax: 15,
                    conditionsError: false,
                    conditions: [],
                    hasCaptcha: false,
                    captchaExecuted: false
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            watch: {
                step: function(oldValue, newValue) {
                    Listeners.MoveToInstance.move(this.$el);
                }
            },
            mounted: function () {
                this.populateElements();
                if(this.livingSituation.length == 0) {
                    this.addRow(this.livingSituation);
                }
                if(this.conditions.length == 0) {
                    this.addRow(this.conditions);
                }
            },

            methods: {
                submit: function () {
                    const that = this;

                    this.populateElements();

                    this.$validator.validateAll().then(result => {
                        let analytics = that.$el.dataset.analytics;

                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                Global.SubmitForm(that.$el, analytics);
                            } else {
                                this.$refs.recaptcha.execute();
                            }
                        } else {
                            let firstError = this.$el.querySelector('.form__invalid-message[show]');
                            if(firstError != null) {
                                Listeners.MoveToInstance.move(firstError);
                            }
                        }
                    });
                },
                populateElements() {
                    // totalSteps berekenen
                    let stepColl = this.$el.querySelectorAll('[data-step]');
                    let totalSteps = stepColl.length;
                    if(totalSteps > 0) {
                        this.titles = {};
                        this.elements = {};
                        for(let s = 0 ; s < totalSteps ; s++) {
                            this.titles[(s+1)] = stepColl[s].dataset.stepTitle;
                            this.elements[(s+1)] = [];
                            let inputElements = stepColl[s].querySelectorAll('[name]');
                            if(inputElements.length > 0) {
                                for(let i = 0 ; i < inputElements.length ; i++) {
                                    this.elements[(s+1)].push(inputElements[i].name);
                                }
                            }
                        }
                    }
                    this.totalSteps = totalSteps;
                },
                checkRowRequirements() {
                    this.livingSituationError = (this.livingSituationMin > 0 && this.livingSituation.length < this.livingSituationMin);
                    this.conditionsError = (this.conditionsMin > 0 && this.conditions.length < this.conditionsMin);
                },
                onCaptchaVerified() {
                    this.captchaExecuted = true;
                    this.submit();
                },
                prev() {
                    this.step--;
                },
                goto(i) {
                  if(i < this.step) {
                      this.step = i
                  }
                },
                next() {
                    const that = this;
                    this.populateElements();
                    this.checkRowRequirements();

                    let validate = true;
                    if(this.step == 5 && (this.livingSituationError || this.conditionsError)) {
                        validate = false;
                    }

                    if(validate) {
                        this.$validator.validateAll(that.elements[that.step]).then(result => {
                            if (result) {
                                this.step++;
                            } else {
                                let firstError = this.$el.querySelector('.form__invalid-message[show]');
                                if(firstError != null) {
                                    Listeners.MoveToInstance.move(firstError);
                                }
                            }
                        });
                    }
                },
                classes(i, prefix) {
                    let arr = [];

                    if(i > this.step) {
                        arr.push(prefix + '--disabled')
                    } else {
                        if(i == this.step) {
                            arr.push(prefix + '--active');
                        } else {
                            arr.push(prefix + '--complete');
                        }

                        var hasErrors = false;
                        if(this.elements[i].length > 0) {
                            for(let e = 0 ; e < this.elements[i].length ; e++) {
                                if(this.errors.has(this.elements[i][e])) {
                                    hasErrors = true;
                                }
                            }
                        }
                        if(hasErrors) {
                            arr.push(prefix + '--errors');
                        }
                    }

                    return arr.join(' ');
                },
                addRow(dataStore) {
                    dataStore.push({});
                    this.checkRowRequirements();
                },
                removeRow(dataStore, x) {
                    dataStore.splice(x, 1);
                    this.checkRowRequirements();
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});